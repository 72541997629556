<template>
  <div class="spinners" v-if="data_await">
    <atom-spinner :animation-duration="1000" :size="100" color="#ff1d5e" />
  </div>
  <header v-if="this.auth.authority">
    <ul>
      <li @click="collapse"><i class="fas fa-bars btn-aside zoom"></i></li>
    </ul>
    <div>
      <div class="input-area-single w225">
        <select class="input" name="mixtureLiter" id="mixtureLiter" v-model="this.$store.state.selected_machine">
          <option v-for="(item, index) in this.$store.state.machine_data" :key="index" :value="item">
            {{ item.code }}
          </option>
        </select>
      </div>
    </div>
    <ul>
      <li id="loginBtn" @click="exitAccount()">
        <i class="fas fa-sign-in-alt"></i>
      </li>
      <li id="fsBtn" @click="fullScreen">
        <i class="fas fa-expand-arrows-alt zoom"></i>
      </li>
    </ul>
  </header>

  <nav v-if="this.auth.authority">
    <div class="logo-details big">
      <div id="logo-small" class="logo d-none">
        <img src="@/assets/media/img/logo.png" alt="" />
      </div>
      <div id="logo-big" class="logo">
        <img src="@/assets/media/img/logo2.png" alt="" />
      </div>
      <span class="logo-name line-limit-1">..\BilirArsiv</span>
    </div>
    <ul class="nav-list">
      <li class="nav-item" v-if="this.authorization?.machine?.root">
        <a class="nav-link" @click="dropdown($event)">
          <i class="fas fa-chart-line"></i>
          <i class="fas fa-chevron-down arrow"></i>
          <span class="nav-title fw-bold">Kontroller</span>
        </a>
        <ul class="sub-list">
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-chart-line"></i>
              <router-link class="nav-title fw-bold" to="/versions">Sürüm</router-link>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-chart-line"></i>
              <router-link class="nav-title fw-bold" to="/systems">Sistem</router-link>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="this.authorization?.user?.read">
        <a class="nav-link">
          <i class="fas fa-users"></i>
          <router-link class="nav-title fw-bold" to="/user">Kullanıcılar</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.authorization?.token?.read">
        <a class="nav-link">
          <i class="fas fa-key"></i>
          <router-link class="nav-title fw-bold" to="/tokens">Lisanslar</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.authorization?.machine?.read">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/machines">Makine listesi</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.authorization?.machine?.read">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/map">Harita</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.authorization?.machine?.read">
        <a class="nav-link">
          <i class="fas fa-chart-line"></i>
          <router-link class="nav-title fw-bold" to="/errors">Uyarılar tablosu</router-link>
        </a>
      </li>
      <hr />
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-paw"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/calfs">Buzağılar</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-paw"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/calf/consumptions">Tüketim miktaları</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-chart-line"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/calf/remainder">Ziyaret kayıtları</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-chart-line"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/calf/specialfeeding">Besleme programı</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-chart-line"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/calf/health">Gelişim takibi</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-chart-line"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/errors">Uyarılar</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-chart-line"></i>
          <router-link class="nav-title fw-bold" to="/dashboard/statistic">İstatistik</router-link>
        </a>
      </li>
    </ul>
    <div class="nav-profil">
      <div class="profil-img">
        <img src="@/assets/media/img/logo.png" alt="" />
      </div>
      <p class="profil-name line-limit">{{ this.auth?.userMail }}</p>
    </div>
  </nav>

  <main class="container-xxxl" v-if="this.auth.authority">
    <div class="content">
      <div class="main-header">
        <p class="page-name">{{ this.breadcrumb[this.breadcrumb.length - 1] }}</p>
        <ul class="breadcrumb">
          <li><a href="/">AnaMenü</a></li>
          <li v-for="(item, index) in this.breadcrumb" :key="index">{{ item }}</li>
        </ul>
      </div>
      <router-view @breadcrumb="breadcrumb = $event" />
    </div>
  </main>

  <footer v-if="this.auth.authority">
    <div class="content">
      <p>©2024</p>
      <p>v2.2.8</p>
    </div>
  </footer>
  <div class="specialLoginArea" v-if="!this.auth?.authority">
    <loginPage />
  </div>
</template>

<style scoped>
.error-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 110;
  width: 100vw;
  padding: 1em;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-alert span {
  max-width: 75%;
}

.fastAccessList {
  padding: 0;
}

.fastAccessList .fastAccessList-item:first-child {
  margin-top: 3em;
}

.fastAccessList .fastAccessList-item {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.fastAccessList .fastAccessList-item i {
  padding: 0 0.5em;
}

.perde {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.3);
}

#technicalNote {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

#technicalNote p {
  margin-bottom: 200px;
  font-size: 1.5em;
  max-width: 500px;
  text-align: center;
  font-weight: bold;
}

input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}

.simple-keyboard {
  max-width: 850px;
}

.specialLoginArea {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import { mapMutations } from "vuex";
import { AtomSpinner } from "epic-spinners";
import loginPage from "@/login.vue";

export default {
  components: {
    AtomSpinner,
    loginPage,
  },
  data() {
    return {
      data_await: true,
      time: 0,
      navOptionsBarShow: false,
      breadcrumb: ["Anamenü"],
    };
  },
  methods: {
    ...mapMutations(["collapse", "fullScreen", "navOptionsBar", "logout"]),
    dropdown(event) {
      event.target.parentElement.parentElement.classList.toggle("active");
      event.target.parentElement.parentElement.classList.toggle("open");
    },
    navOptionsBar0() {
      this.navOptionsBar();
      this.navOptionsBarShow = !this.navOptionsBarShow;
    },
    async checkByToken() {
      if (localStorage.token && localStorage.token != undefined) {
        try {
          debugger;
          var req = await this.$axios.get({ uri: "/checkByToken" });
          if (req.newToken) {
            const response = req;
            localStorage.token = response?.newToken;
            this.$store.state.auth = {
              authority: true,
              role: response?.role,
              mail: response?.mail,
              time: response?.exp,
              authorization: response?.authority,
              machines: response?.machines,
            };
          }
          else this.logout()
        } catch (error) {
          if (error.response) this.$alertBox.warning(error.response.data);
          else console.error(error.message);
          this.logout();
          this.$router.go();
        }
      } else this.logout();
      this.spinners = false;
    },
    exitAccount() {
      this.$swal({
        title: "Çıkış yapmak istediğinize emin misiniz",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "Evet",
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.logout();
        }
      });
    },
  },
  async mounted() {
    this.checkByToken();
    setInterval(() => { this.checkByToken() }, 3 * 60 * 1000);
    setInterval(() => { this.time = this.$store.state.auth.time - Number((new Date().getTime() / 1000).toFixed()) }, 1000);
    this.data_await = false;
    const machine_data = await this.$axios.get({ uri: "/machines/all" });
    this.$store.state.machine_data = machine_data.data
      ?.filter((x) => x.code.split("-")[0] == "CFR" && x.remote_ip != null)
      .sort((a, b) => a.code.split("-")[1] - b.code.split("-")[1]);
    if (this.$store.state.machine_data)
      this.$store.state.selected_machine = this.$store.state.machine_data[0];
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    authorization() {
      return this.$store.state.auth.authorization;
    },
  },
};
</script>
