<template>
    <div class="box-white loginCard" style="width: 500px">
        <div class="img">
            <img src="./assets/media/img/logo.png" alt="">
        </div>
        <div class="header">
            <p v-if="this.valuePage">Giriş Yap</p>
            <p v-else>Kayıt Ol</p>
        </div>
        <div class="content my-3">
            <div class="row" v-if="this.valuePage" @keyup.enter="login()">
                <div class="col-12 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.auth.email" required>
                        <label>Kullanıcı adı</label>
                    </div>
                </div>
                <div class="col-12 my-3">
                    <div class="input-box">
                        <input type="password" v-model="this.auth.pass" required>
                        <label>Şifre</label>
                    </div>
                </div>
            </div>
            <div v-else class="row" @keyup.enter="register()">
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.user.name" required>
                        <label>Ad</label>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.user.surname" required>
                        <label>Soyad</label>
                    </div>
                </div>
                <div class="col-12 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.user.mail" required>
                        <label>Kullanıcı adı</label>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="password" v-model="this.passControl1" required>
                        <label>Şifre</label>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="password" v-model="this.passControl2" required>
                        <label>Tekrar Şifre</label>
                    </div>
                </div>
                <div class="col-12">
                    
                </div>
            </div>

        </div>
        <div class="buttons">
            <div class="ps-3">
                <span v-if="this.valuePage" @click="this.valuePage = false">Hesabım yok</span>
                <span v-else @click="this.valuePage = true">Hesabım ile giriş yap</span>
            </div>
            <div>
                <button class="btn bg-info float-end mx-1" @click="register()" v-if="!this.valuePage" :disabled="btnDelay">Kayıt Ol</button>
                <button class="btn bg-success text-white float-end mx-1" @click="login()" v-if="this.valuePage" :disabled="btnDelay">Giriş</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.loginCard {
    position: relative;
    border-radius: .5em;
    padding: 1em;
}

.loginCard .img {
    height: 100px;
}

.loginCard .img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.loginCard .header {
    width: 100%;
    text-align: center;
    padding: 1em;
    font-size: 1.5em;
}

.loginCard .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 1em;
}
</style>

<script>
import { mapMutations } from "vuex";

export default {
    name: 'loginPage',
    data() {
        return {
            valuePage: true,
            btnDelay: false,
            auth: {
                email: '',
                pass: ''
            },
            passControl1: '',
            passControl2: '',
            user: {
                name: '',
                surname: '',
                mail: '',
                phone: '',
                password: ''
            },
        }
    },
    methods: {
        ...mapMutations([
            "logout"
        ]),
        async login() {
            this.btnDelay = true
            setTimeout(() => {
                this.btnDelay = false
            }, 1000);
            try {
                var req = await this.$axios.get({ uri: '/login', valueData: { mail: this.auth.email, password: this.auth.pass }})
                if (req) {
                    localStorage.token = req
                    this.$router.push('/')
                    this.$router.go()
                }
                else this.$alertBox.success(req)
            } catch (error) {
                this.$alertBox.warning(error.response.data.message)
            }
        },
        async register() {
            this.btnDelay = true
            setTimeout(() => {
                this.btnDelay = false
            }, 1000);
            this.$swal({
                title: 'Bilgilerimi kaydet',
                showCancelButton: false,
                showDenyButton: true,
                confirmButtonText: 'İşlemi onayla',
                denyButtonText: `Vazgeç`,
                timer: this.$store.state.toastrAlert.alertduration,
                timerProgressBar: true,
            })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        if (this.user.name.length < 3 || this.user.surname.length < 3) this.$alertBox.warning('Kullanıcı adı veya soyadı bilgileri 3 karakterden kısa olamaz')
                        else if (this.passControl1 != this.passControl2) this.$alertBox.warning('Şifreler uyuşmuyor.')
                        else if (this.user.password.length < 8) this.$alertBox.warning('Şifreniz en az 8 karakterden oluşmalıdır.')
                        else {
                            this.user.password = await this.$crypto.sha256(this.user.password).toString()
                            await this.$axios.post({ uri: '/users/add', valueData: this.user })
                            this.auth.email = this.user.mail
                            this.valuePage = true
                        }
                    }
                })
        }
    },
    async mounted() {
        if (localStorage.token != undefined) this.$router.replace({ query: null })
    },
    computed: {
    },
    watch: {
        passControl1() {
            if (this.passControl1 == this.passControl2) this.user.password = this.passControl1
            else this.user.password = ''
        },
        passControl2() {
            if (this.passControl1 == this.passControl2) this.user.password = this.passControl2
            else this.user.password = ''
        }
    }
}
</script>
