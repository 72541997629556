import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/:catchAll(.*)', redirect: '/', },

  { path: '/', name: "myprofil", component: () => import('@/views/general/profil.vue') },
  { path: '/user', name: "auth-user", component: () => import('@/views/panel/users/user.vue') },
  { path: '/tokens', name: "token", component: () => import('@/views/panel/tokens/tokens.vue') },

  { path: '/machines', name: "machine", component: () => import('@/views/panel/machines/machines.vue') },
  { path: '/map', name: "map", component: () => import('@/views/panel/machines/map.vue') },
  { path: '/versions', name: "versions", component: () => import('@/views/panel/machines/versions.vue') },
  { path: '/systems', name: "systems", component: () => import('@/views/panel/machines/systems.vue') },
  { path: '/errors', name: "errors", component: () => import('@/views/panel/machines/errors.vue') },
  
  { path: '/dashboard/calfs', name: "dashboard-calfs", component: () => import('@/views/dashboard/CalfList.vue') },
  { path: '/dashboard/calf/details', name: "dashboard-calfs-details", component: () => import('@/views/dashboard/calfDetails.vue') },
  { path: '/dashboard/calf/consumptions', name: "dashboard-calf-consumptions", component: () => import('@/views/dashboard/calfConsumption.vue') },
  { path: '/dashboard/calf/remainder', name: "dashboard-calf-remainder", component: () => import('@/views/dashboard/calfRemainder.vue') },
  { path: '/dashboard/calf/health', name: "dashboard-calf-health", component: () => import('@/views/dashboard/CalfDiaphragm.vue') },
  { path: '/dashboard/errors', name: "dashboard-errors", component: () => import('@/views/dashboard/errorLog.vue') },
  { path: '/dashboard/statistic', name: "dashboard-statistic", component: () => import('@/views/dashboard/statistics.vue') },
  { path: '/dashboard/calf/specialfeeding', name: "dashboard-calf-specialfeeding", component: () => import('@/views/dashboard/specialfeeding/specialFeeding.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  next();
});

export default router
